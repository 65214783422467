import * as i0 from '@angular/core';
import { Injector, inject, Directive, Input, ElementRef, Injectable, Component, ChangeDetectionStrategy, ApplicationRef, createComponent } from '@angular/core';
const LfCreateInjectorFn = () => {
  const parent = inject(Injector);
  return providers => Injector.create({
    providers,
    parent
  });
};
function lfGetRandomFromArray(array) {
  return array[Math.floor(Math.random() * array.length)];
}
function lfSortArray(array, sorting) {
  if (sorting.property === null) {
    return [...array];
  }
  return [...array].sort((a, b) => {
    let first = a[sorting.property];
    let second = b[sorting.property];
    if (sorting.direction === 'asc') {
      return first > second ? 1 : -1;
    } else {
      return first < second ? 1 : -1;
    }
  });
}
const LfCanBeDeactivatedFn = component => {
  return component ? component.lfCanBeDeactivated() : true;
};
class LfAccentClassDirective {
  constructor() {
    this.accent = 'brand';
  }
  static {
    this.ɵfac = function LfAccentClassDirective_Factory(t) {
      return new (t || LfAccentClassDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LfAccentClassDirective,
      selectors: [["", "lfAccentClass", ""]],
      hostVars: 2,
      hostBindings: function LfAccentClassDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap("lf-" + ctx.accent);
        }
      },
      inputs: {
        accent: [i0.ɵɵInputFlags.None, "lfAccentClass", "accent"]
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfAccentClassDirective, [{
    type: Directive,
    args: [{
      selector: '[lfAccentClass]',
      standalone: true,
      host: {
        '[class]': `'lf-' + accent`
      }
    }]
  }], null, {
    accent: [{
      type: Input,
      args: ['lfAccentClass']
    }]
  });
})();
class LfAutofocusDirective {
  constructor() {
    this.elementRef = inject(ElementRef);
    this.autofocusStrategy = 'default';
    this.autofocusDelay = 250;
    this.preventAutofocus = false;
  }
  trigger() {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    }, this.autofocusDelay);
  }
  ngAfterViewInit() {
    if (this.autofocusStrategy === 'default' && !this.preventAutofocus) {
      this.trigger();
    }
  }
  static {
    this.ɵfac = function LfAutofocusDirective_Factory(t) {
      return new (t || LfAutofocusDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LfAutofocusDirective,
      selectors: [["", "lfAutofocus", ""]],
      inputs: {
        autofocusStrategy: "autofocusStrategy",
        autofocusDelay: "autofocusDelay",
        preventAutofocus: "preventAutofocus"
      },
      exportAs: ["lfAutofocus"],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfAutofocusDirective, [{
    type: Directive,
    args: [{
      selector: '[lfAutofocus]',
      exportAs: 'lfAutofocus',
      standalone: true
    }]
  }], null, {
    autofocusStrategy: [{
      type: Input
    }],
    autofocusDelay: [{
      type: Input
    }],
    preventAutofocus: [{
      type: Input
    }]
  });
})();
const beforeUnloadHandler = event => {
  // Recommended
  event.preventDefault();
  // Included for legacy support, e.g. Chrome/Edge < 119
  event.returnValue = true;
};
class LfTabClosingConfirmationService {
  constructor() {
    this._activated = false;
  }
  get activated() {
    return this._activated;
  }
  activate() {
    this._activated = true;
    window.addEventListener('beforeunload', beforeUnloadHandler);
  }
  deactivate() {
    this._activated = false;
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  }
  static {
    this.ɵfac = function LfTabClosingConfirmationService_Factory(t) {
      return new (t || LfTabClosingConfirmationService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LfTabClosingConfirmationService,
      factory: LfTabClosingConfirmationService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfTabClosingConfirmationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const TEMPLATE = ``;
const STYLES = `
:host {
  position: absolute;
  top: -99999px;
  width: 200px;
  height: 200px;
  overflow: hidden scroll;
}
`;
class LfScrollbarDetectorComponent {
  constructor() {
    this.elementRef = inject(ElementRef);
    this.nativeEl = this.elementRef.nativeElement;
  }
  getWidth() {
    return this.nativeEl.offsetWidth - this.nativeEl.clientWidth;
  }
  static {
    this.ɵfac = function LfScrollbarDetectorComponent_Factory(t) {
      return new (t || LfScrollbarDetectorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfScrollbarDetectorComponent,
      selectors: [["lf-scrollbar-detector"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function LfScrollbarDetectorComponent_Template(rf, ctx) {},
      styles: ["[_nghost-%COMP%]{position:absolute;top:-99999px;width:200px;height:200px;overflow:hidden scroll}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfScrollbarDetectorComponent, [{
    type: Component,
    args: [{
      selector: 'lf-scrollbar-detector',
      standalone: true,
      imports: [],
      template: TEMPLATE,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [":host{position:absolute;top:-99999px;width:200px;height:200px;overflow:hidden scroll}\n"]
    }]
  }], null, null);
})();
const BODY_CLASSES = {
  WITH: 'lf-device_with-scrollbars',
  WITHOUT: 'lf-device_without-scrollbars'
};
class LfScrollbarStrategyService {
  constructor() {
    this.appRef = inject(ApplicationRef);
  }
  injectChecker() {
    this.checkerRef = createComponent(LfScrollbarDetectorComponent, {
      environmentInjector: this.appRef.injector
    });
    document.body.append(this.checkerRef.location.nativeElement);
  }
  destroyChecker() {
    this.checkerRef?.location.nativeElement.remove();
    this.checkerRef?.destroy();
  }
  getWidth() {
    return this.checkerRef?.instance.getWidth() || 0;
  }
  addClassesToBody() {
    document.body.classList.add(this.getWidth() === 0 ? BODY_CLASSES.WITHOUT : BODY_CLASSES.WITH);
    document.body.classList.add(`lf-scrollbars_${this.strategy}`);
  }
  setScrollbarVar() {
    document.documentElement.style.setProperty('--lf-scrollbar-width', this.getWidth() + 'px');
  }
  use(strategy) {
    this.strategy = strategy;
    this.injectChecker();
    this.addClassesToBody();
    this.setScrollbarVar();
    this.destroyChecker();
  }
  getStrategy() {
    return this.strategy;
  }
  static {
    this.ɵfac = function LfScrollbarStrategyService_Factory(t) {
      return new (t || LfScrollbarStrategyService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LfScrollbarStrategyService,
      factory: LfScrollbarStrategyService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfScrollbarStrategyService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LfAccentClassDirective, LfAutofocusDirective, LfCanBeDeactivatedFn, LfCreateInjectorFn, LfScrollbarStrategyService, LfTabClosingConfirmationService, lfGetRandomFromArray, lfSortArray };
